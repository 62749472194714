<template>
  <div class="all__access">
    <div class="access">
      <div class="access__left">
        <div class="access__left-title">
          <span class="title__name">Общие доступы</span>
        </div>
        <div class="access__left-search">
          <input
            class="access__left-search__input"
            placeholder="Поиск"
            v-model="searchItem"
            @input="search"
          />
          <UiIcon
            value="skif_search"
            width="20px"
            height="20px"
            class="icon__search"
          />
        </div>
        <div class="access__left-settings">
          <div class="access__left-settings__apply">
            <el-select
              v-model="Store.state.accessSettings.getRole"
              placeholder="Применить шаблон"
              :disabled="isDisabled"
              @change="changeSamplesHandler"
            >
              <el-option
                v-for="(item, index) in [...roles]"
                :key="index"
                :value="item"
                :label="item.value"
              />
            </el-select>
          </div>
          <div
            :class="['clear__select', { disabled: isDisabled }]"
            @click="deleteSelected"
          >
            Очистить выбор
          </div>
        </div>
      </div>
      <div class="access__right">
        <div class="access__right-settings">
          <div class="access__right-settings-add">
            <div class="access__info" v-if="buttonActive">
              <UiIcon value="orange-info" width="22px" height="22px" />
              <span>Осторожно, вы не сохранили изменения!</span>
            </div>
            <el-button
              :class="['access__add', { 'active-btn': buttonActive }]"
              :disabled="!buttonActive"
              @click="addSettings"
            >
              Сохранить
              <!--             Store.state.accessSettings.checkedSamples.length &&-->
            </el-button>
          </div>
          <div
            class="close__img cursor-pointer"
            @click="Store.commit('accessSettings/setIsAccessSettings', false)"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z"
                fill="#C93131"
              />
            </svg>
          </div>
        </div>
        <div class="change__role-name">
          <div class="title">Название роли:</div>
          <input
            v-model="item.value"
            :disabled="disabledRoleName"
            @input="buttonActive = true"
            class="role-name__input"
          />
        </div>
      </div>
    </div>
    <SamplesSettings
      v-if="showActions"
      key="key"
      :actions="itemsActions"
      :disabled="disabled"
      @checkboxValueIsChanged="checkboxValueIsChanged"
    />
    <div v-else class="loader">
      <UiLoaderLine />
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  defineProps,
  onMounted,
  ref,
  reactive,
  getCurrentInstance
} from 'vue'
import SamplesSettings from '@/components/companyControlsCenter/components/AccessSettings/samplesSettings.vue'
import UiLoaderLine from '@/components/unitsNew/components/modalSensors/components/loaderLine.vue'
import UiIcon from './ui/icon.vue'
import { useStore } from '@/store/store.js'
import { useI18n } from '@/hooks/useI18n'

const Store = useStore()
const showActions = ref(true)
const searchItem = ref('')
const buttonActive = ref(false)
const initialCheckedSamples = ref([])

const checkboxValueIsChanged = () => {
  const currentChecked = Store.state.accessSettings.checkedSamples.map(
    (item) => item.id
  )
  const isChanged =
    initialCheckedSamples.value.some((id) => !currentChecked.includes(id)) ||
    currentChecked.some((id) => !initialCheckedSamples.value.includes(id))

  buttonActive.value = isChanged
}

const changeSettingsForm = reactive({
  id: '',
  is_default: false,
  company_id: '',
  value: '',
  actions: []
})

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const isDisabled = computed(() => {
  if (
    props.item.value === 'Без доступа' ||
    props.item.value === 'Наблюдатель' ||
    props.item.value === 'Читатель' ||
    props.item.value === 'Оператор' ||
    props.item.value === 'Редактор' ||
    props.item.value === 'Администратор' ||
    props.item.value === 'Супервизор' ||
    props.item.value === 'Редактор тарифа' ||
    props.item.value === 'Контроллер'
  ) {
    return true
  } else {
    return false
  }
})

const roles = computed(() => {
  return Store.getters['accessSettings/getAllRoles']
})

const active = computed(() => {
  return Store.state.accessSettings.checkedSamples.length
})

const getActionsItems = computed(() => {
  return Store.getters['accessSettings/getAllActions']
})

const disabledRoleName = computed(() => {
  if (
    props.item.id === '3fbd6629-f854-46e9-a190-cae0eb7fb5f0' ||
    props.item.id === '808942ea-a102-47cb-889d-859b182f160c' ||
    props.item.id === '7d7292e4-e246-44e1-ac70-b1c7f23e464c' ||
    props.item.id === '6592d562-e965-43bd-9d9e-8319cabd6291' ||
    props.item.id === '8ae01b09-95dc-445a-8b92-b570cfa94a04' ||
    props.item.id === '41607791-8948-460b-8950-d60a9c57c372' ||
    props.item.id === 'd9566448-3518-4389-8a39-662f4d6e71ec'
  ) {
    return true
  }
  return false
})
const changeSamplesHandler = () => {
  showActions.value=false
  const templateActions = Store.state.accessSettings.getRole.actions
  Store.state.accessSettings.checkedSamples=templateActions
  setTimeout(()=>{
    showActions.value=true
    buttonActive.value=true
    Store.state.accessSettings.getRole=''
  },1000)
}

const itemsActions = computed(() => {
  const actionsItems = getActionsItems.value.filter((val) => !val.is_unit_action)
  const viewActions = actionsItems.filter((val) => val.key.includes('get_'))
  const creationChange = actionsItems.filter((val) => val.key.includes('cud_'))
  const basicActions = actionsItems.filter((val) => {
    if (!val.key.includes('get_') && !val.key.includes('cud_')) {
      return val
    }
  })

  return [
    {
      title: 'ОСНОВНЫЕ',
      items: basicActions
    },
    {
      title: 'ПРОСМОТР',
      items: viewActions
    },
    {
      title: 'СОЗДАНИЕ И ИЗМЕНЕНИЕ',
      items: creationChange
    }
  ]
})

const selectedItems = computed(() => {
  return Store.state.accessSettings.checkedSamples.length
})

const search = () => {
  Store.dispatch('accessSettings/getAllActions', searchItem.value)
}

const deleteSelected = () => {
  if (!isDisabled.value) {
    Store.state.accessSettings.loader = !Store.state.accessSettings.loader
    itemsActions.value.forEach((val) =>
      val.items.forEach((item) => {
        item.checked = false
      })
    )
    Store.state.accessSettings.checkedSamples = []
  }
}
const instance = getCurrentInstance()
const $t = useI18n()

const addSettings = (items) => {
  const getActionsPayload = Store.state.accessSettings.checkedSamples.length
    ? Store.state.accessSettings.checkedSamples
    : props.item.actions
  if (Store.state.accessSettings.checkedSamples.length) {
    changeSettingsForm.id = props.item.id
    changeSettingsForm.is_default = props.item.is_default
    changeSettingsForm.company_id = props.item.company_id
    changeSettingsForm.value = props.item.value
    changeSettingsForm.actions = getActionsPayload
    showActions.value = false

    setTimeout(() => {
      console.log(changeSettingsForm, 'changeSettingsForm')
      Store.dispatch('accessSettings/addChangeSettings', changeSettingsForm)
      showActions.value = true
      buttonActive.value = false
    }, 300)
  } else {
    instance.proxy.$showError(
      $t('Перед сохранением нужно выбрать хотя бы доступ!')
    )
  }
}

onMounted(async () => {
  Store.state.accessSettings.getRole = ''
  itemsActions.value.forEach((group) => {
    group.items.forEach((item) => {
      if (item.checked) {
        initialCheckedSamples.value.push(item.id)
      }
    })
  })
  await Store.dispatch('accessSettings/getAllActions')
})
</script>

<style scoped lang="scss">
.all__access {
  background: #f6f7fa;
  width: 100%;
  padding: 20px;
}
.access {
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dce1e7;
  &__left {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &-title {
      display: flex;

      .title__name {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        color: #292929;
        margin-right: 8px;
      }

      .title__icon {
        background: #edeff3;
        border-radius: 100%;
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-search {
      position: relative;

      &__input {
        width: 629px;
        padding: 8px 8px 6px 33px !important;
        height: 36px !important;
        border-radius: 10px;
        border: 1px solid #dcdfe6;
        background: #fff;
        color: #a6aeb8;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        &:focus {
          outline: none;
        }
        //::v-deep(.el-input__inner) {
        //  width: 100%;
        //  height: 36px !important;
        //  padding: 8px;
        //}
      }

      .icon__search {
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }

    &-settings {
      display: flex;
      gap: 24px;
      align-items: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;

      &__apply {
        ::v-deep(.el-select) {
          .el-input__inner {
            background: transparent;
            font-size: 14px !important;
            font-weight: 600;
            width: 165px;
            height: 20px !important;
            padding: 0;
            color: rgba(58, 124, 202, 1);
            border: none;
          }

          .el-input__inner::placeholder {
            color: #3a7cca;
            font-size: 14px !important;
            font-weight: 600;
          }
          .el-input__inner:focus {
            border: transparent;
          }
          .el-icon-arrow-up::before {
            color: rgba(58, 124, 202, 1) !important;
          }
        }
      }

      &__clear {
      }
      .clear__select {
        color: #ea7644;
        cursor: pointer;

        &.disabled {
          color: grey;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 43px;

    &-settings {
      display: flex;
      column-gap: 28px;
      align-items: center;

      &-add {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;

        .access__info {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 6px;
          border-radius: 12px;
          background: #ffe8db;
          color: #ca5f32;
          font-size: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          padding: 10px 12px;
          max-width: 270px;
          height: 36px;
        }
        .access__add {
          width: 160px;
          height: 36px;
          background: #bec7d3;
          color: #ffffff;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .active-btn {
          background: #3a7cca;
        }
      }

      .close__img {
        width: 32px;
        height: 32px;
        background: #edeff3;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //padding: 10px;
      }
    }

    .change__role-name {
      display: flex;
      column-gap: 8px;
      align-items: center;

      .title {
        white-space: nowrap;
        color: #828d9b;
      }
      .role-name__input {
        padding-left: 12px;
        width: 280px;
        height: 36px;
        border-radius: 10px;
        border: 1px solid #dce1e7;
        background: #edeff3;
        color: var(--black-tone-1, #292929);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        &:focus {
          outline: none;
        }
      }
    }
  }

  &__samples {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 20px 0;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}
::v-deep(.select) {
  cursor: pointer;
  .select-header {
    background: transparent;
    align-items: center;
    justify-content: normal;
    column-gap: 6px;
    text-overflow: ellipsis;

    .select-header__text {
      color: #3a7cca !important;
      font-size: 14px;
      font-weight: 600;
    }

    .select-header__text.placeholder {
      color: #3a7cca !important;
      font-size: 14px;
      font-weight: 600;
    }

    .select-img {
      margin-left: 6px;
    }
  }
}
.loader {
  width: 100px;
  margin: 100px auto;
}
::v-deep(.el-input__prefix) {
  color: #292929;
}
::v-deep(.access__left-search__input.el-input) {
  .el-input__inner {
    padding: 8px 8px 6px 33px !important;
  }
}
</style>
