<template>
  <div>
    <label class="groups" v-for="(item, idx) in data" :key="idx">
      <div class="groups__group" @click.prevent="$emit('objectIsActive', $event, item)">
        <div class="groups__group-left">
          <div class="groups__group-left-info">
            <div :class="['group__name', { 'active' : item.name === current.name }]">{{ item.name }}</div>
            <div class="group__count" @click.prevent="$emit('openModalDelete', $event, item)">Объектов: {{ item.units.length }}</div>
          </div>
          <div class="groups__group-left-img">
            <UiIcon
                value="arrow"
                width="5px"
                height="8px"
            />
          </div>
        </div>
        <div v-if="item.showPopup" class="groups__group-add">
          <AddUsers
              :users="item.units"
              addUsersType="objects"
              buttonName="Удалить"
              @deleteItem="removeItem"
          />
        </div>
      </div>
      <input type="radio" name="name" :value="item.name" v-model="current">
    </label>
  </div>
</template>

<script setup>
import UiIcon from "./ui/icon.vue"
import AddUsers from "../AccessSettings/addUsers.vue";
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  current:{
    type:Object,
    default:()=>{}
  }
})


const emit = defineEmits(['deleteItem','openModalDelete'])

const removeItem = (id) => {
  emit('deleteItem', { id: id })
}

</script>

<style scoped lang="scss">
.groups {
  position: relative;
  &__group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 7px 18px 9px 20px;
    border-bottom: 1px solid #F6F7FA;
    cursor: pointer;

    .transform {
      transform: rotate(90deg);
    }
    &-left {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-info {
        .group__name {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          color: #292929;
        }
        .active {
          color: #5C9DEB;
        }
        .group__count {
          font-size: 9px;
          font-style: normal;
          font-weight: 600;
          line-height: 80%;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #A6AEB8;
        }
        .group__count:hover {
          color: #5C9DEB;
        }
      }
      &-img {
        cursor: pointer;
      }
    }
    &:hover {
      background: #EDEFF3;
    }

    &-add {
      overflow: hidden;
    }
  }
}

input {
  display: none;
}

</style>
