<template>
  <div class="table__user"
       @mouseenter="openPopup"
       @mouseleave="closePopup"
  >
    <div class="table__user-left">
      <div class="user__img">
<!--        <img v-if="rowObject.photos[1]" :src="'/' + 'api_v1' + rowObject.photos[1].url" alt="">-->
        <img src="/img/Avatar.c6df6ded.svg" alt="">
      </div>
      <div class="user__info">
        <div class="user__info-name">{{rowName}}</div>
        <div class="user__info-role">{{rowInfo}}</div>
      </div>
    </div>
    <div class="table__user-right" >
      <div class="icon" v-if="!showPopup">
        <UiIcon
            value="grey_more"
            width="4px"
            height="10px"
        />
      </div>
      <div  class="commands">
        <UiPopup v-if="showPopup" v-model="popup">
          <template #activator>
            <UiIcon
                color="#3A7CCA"
                value="blue_more"
                width="4px"
                height="10px"
            />
          </template>
          <template #body>
            <div class="button__body">
              <div class="button__body-change item" @mouseenter="change = true">
                <span v-if="roleName === 'Группы доступа'">Изменить роль на</span>
<!--                <span v-if="roleName === 'Пользователи (Группы)'">Применить шаблон</span>-->
                <UiIcon v-if="roleName === 'Группы доступа'" value="smallArrowUp" class="transform" height="6px" width="10px" />
              </div>
              <div
                  v-if="roleName === 'Группы доступа'"
                  @mouseenter="closeRoles"
                  class="button__body-edit item"
              >
                Редактировать роль
              </div>
              <div

                  @mouseenter="closeRoles"
                  class="button__body-delete item"
                  @click="$emit('deleteUser', row)"
              >
                <span v-if="roleName === 'Группы доступа'">Удалить из группы</span>
                <span v-if="roleName === 'Пользователи (Группы)'">Исключить пользователя</span>
              </div>
              <div v-if="roleName === 'Группы доступа'" class="change__roles" @mouseleave="closeRoles">
                <ChangeRoles
                    v-if="change"
                    :items="roles"
                />
              </div>
            </div>
          </template>
        </UiPopup>
      </div>
    </div>
  </div>

</template>

<script setup>
import {defineProps, defineEmits, ref, computed } from 'vue'
import UiIcon from "./ui/icon.vue"
import UiPopup from './ui/popup.vue'
import ChangeRoles from "@/components/companyControlsCenter/components/AccessSettings/changeRoles.vue";

const props = defineProps({
  row: {
    type: Object,
    default: () => {}
  },
  rowObject: {
    type: Object,
    default: () => {}
  },
  isShowPopup: {
    type: Boolean,
    default: false
  },
  isPopup: {
    type: Boolean,
    default: false
  },
  roleName: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['deleteUser'])

const roles = ref([
  {name: 'Читатель', id: 1, popup: false, showPopup: false},
  {name: 'Редактор тарифа', id: 2, popup: false, showPopup: false},
  {name: 'Редактор', id: 3, popup: false, showPopup: false},
  {name: 'Админ', id: 4, popup: false, showPopup: false},
  {name: 'Бухгалтер', id: 5, popup: false, showPopup: false},
  {name: 'Читатель', id: 6, popup: false, showPopup: false},
])
const showPopup = ref(props.isShowPopup)
const popup = ref(props.isPopup)
const change = ref(false)

const positionStyle = computed(() => {
  if(props.roleName === 'Группы доступа') {
    return '386px'
  } else if (props.roleName === 'Пользователи (Группы)') {
    return '360px'
  }
})
const positionRoleItems = computed(() => {
  if(props.roleName === 'Группы доступа') {
    return '176px'
  } else if (props.roleName === 'Пользователи (Группы)' ) {
    return '203px'
  }
})
const openPopup = () => {
  closeRoles()
  showPopup.value = true
}
const closePopup = () => {
  showPopup.value = false
  popup.value = false
}
const closeRoles = () => {
  change.value = false
}
const rowName = computed(() => {
  return props.row.user ? props.row.user.name : props.row.name
})
const rowInfo = computed(() => {
  return  props.row.user ?  props.row.user.role.value : 'ОБЪЕКТОВ:' + props.row.units.length
})

</script>

<style scoped lang="scss">
.table__user {
  display: flex;
  justify-content: space-between;
  padding: 2px 4px 1px 8px;
  height: 100%;
  &:hover {
    background: #F6F7FA;
  }

  &-left {
    display: flex;
    column-gap: 4px;
    align-items: center;

    .user__img {
      display: flex;
      align-items: center;
      justify-content:center;
      overflow: hidden;
      border: 1px solid #e2e8f0;
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }

    .user__info {
      text-align: left;

      &-name {
        max-width: 166px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #292929;
        white-space: nowrap;
      }

      &-role {
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: 80%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #A6AEB8;
      }
    }
  }

  &-right {
    cursor: pointer;
    position: relative;

    .icon {
      border-radius: 100%;
      width: 20px;
      height: 20px;
    }

    .commands {
      position: absolute;
      top: 3px;
      right: 0;

      ::v-deep(.popup) {

        .popup__activator {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
        }

        .popup__body {
          border: 1px solid #DCE1E7;
          left: v-bind(positionStyle) !important;

          .button__body {
            padding: 9px 0;
            display: flex;
            flex-direction: column;
            row-gap: 7px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            color: #292929;
            position: relative;
            height: max-content;


            &-change {

              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 16px;

              .transform{
                transform: rotate(270deg);
                color: #FFFFFF;
              }
            }

            .item {
              white-space: nowrap;
              text-align: left;
              padding: 0 12px;
            }

            &-delete {
              color: #C93131;
            }
          }

          .change__roles {
            top: 1px;
            position: absolute;
            left: v-bind(positionRoleItems);

          }
        }
      }
    }
  }
}
</style>
