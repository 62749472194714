<template>
  <div class="essence" >
    <div class="essence__header">
      <div class="essence__header-left">
       <div class="header__title">
         <span>Доступы пользователей к объектам</span>
         <div class="header__title-icon">
           <UiIcon value="information-v2" height="16px" width="16px"/>
         </div>
       </div>
        <div class="header__info">
          <UiIcon value="orange-info" color="red" width="22px" height="22px"  />
          <span>Настройка доступа осуществляется по средствам добавления объекта в группу.</span>
        </div>
      </div>
      <div class="essence__header-right" @click="Store.commit('accessSettings/setIsAccessSettings', false)">
        <UiIcon value="allAccessClose" height="12px" width="12px"/>
      </div>
    </div>
    <div class="essence__body">
      <div class="essence__body-header">
          <div class="user__info">
            <div class="user__info-name">{{ userInfo.name }}</div>
            <div class="user__info-count">(Доступно объектов: {{ unitObjects.length}})</div>
          </div>
          <div class="search__group">
            <UiIcon value="skif_search" height="20px" width="20px" class="search__group-icon"/>
            <div class="search__group-select">
              <el-select
                  placeholder="Название"
                  v-model="selectName"
              >
                <el-option
                    v-for="item in itemsName"
                    :key="item.id"
                    :value="item.name"
                />
              </el-select>
            </div>
            <div class="search__group-input">
              <input
                  class="input-inner"
                  placeholder="Поиск"
                  v-model="searchObject"
              />
            </div>
          </div>
          <div class="group__settings-icons" >
            <div class="columns__icon icons">
              <UiIcon color="#292929" value="columns"  width="20px" height="20px" @click="showModal = !showModal"/>
            </div>
            <div class="more__icon icons">
              <div class="more__icon-radius">
                <UiIcon color="#292929" value="more" width="14px" height="4px" />
              </div>
            </div>
          </div>
        </div>
      <div class="essence__body-table" :key="tableKey">
        <div class="table__content">
          <table>
            <thead>
            <tr class="table__row">
              <th class="first__item">-</th>
              <th v-for="column in columns">
                <div class="table__content_item">{{ column.name }}</div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in unitObjects" v-if="row">
              <td>
                <ObjectInfo
                    :isPopup="row.popup"
                    :isShowPopup="row.showPopup"
                    :row="row"
                    roleName="Пользователи (Сущность)"
                />
              </td>
              <td v-for="column in columns" :key="column.id">
                <UiTooltip
                    :type="'access'"
                    background="#DBEBFF"
                    color="#20579A"
                    @toGroupAccess="$emit('changeGroup')"
                    buttom
                    :groups-names="row.groupNames.join(', ')"
                >
                  <template #activator>
                  <input
                    type="checkbox"
                    :checked="row.groupData.actions.some(action => action.id === column.id)"
                    disabled
                 />
                  </template>
                </UiTooltip>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, computed} from "vue"
import UiTooltip from './ui/tooltip.vue'
import UiIcon from "./ui/icon.vue"
import ObjectInfo from "../AccessSettings/objectInfo.vue"
import { useStore } from '@/store/store.js'

const Store = useStore()
const searchObject = ref('')
const selectName = ref('Группы')
const itemsName = ref([
  { name: 'IMEI', id: 133131131},
  { name: 'Группы', id: 241414}
])
const showModal = ref(false)
const props = defineProps({
  userInfo: {
    type: Object,
    default: () => {}
  },
  objects: {
    type: Array,
    default: () => []
  },
  groupId: {
    type: String,
    default: ''
  }
})
const tableKey = computed(() => {
  return `table-${props.groupId}`;
});
const getActionsItems = computed(() => {
  return Store.getters['accessSettings/getAllActions']
})
const columns = ref(
    getActionsItems.value.filter((c) => {
      if (c !== null) {
        return c._unit_action
      }
    })
)

const findMatchingUnits = (arr1, arr2) => {
  let combinedUnits = [];

  arr2.forEach(item2 => {
    item2.units.forEach(unit => {
      // Находим все группы, в которых присутствует данный unit
      const groupNames = arr2
          .filter(group => group.units.some(u => u.id === unit.id))
          .map(group => group.name);

      const groupData = arr1.find(item1 => item1.units_group.id === item2.id);
      if (groupData) {
        combinedUnits.push({
          ...unit,
          groupNames, // Добавляем массив с названиями всех групп
          groupData: {
            units_group: groupData.units_group,
            actions: groupData.actions
          }
        });
      }
    });
  });

  return combinedUnits;

}
const unitObjects = computed(() => {
  const matchingUnits = findMatchingUnits(props.userInfo.units_groups_access, Store.getters['units/unitsGroup']);
  if (searchObject.value && selectName.value === 'Группы') {
    return matchingUnits.filter((item) => {
      return  item.groupNames.some(el=>el.toLowerCase().includes(searchObject.value.toLowerCase()))  ? item : null
    })
  } else if(searchObject.value && selectName.value === 'IMEI') {
    return matchingUnits.filter((item) => {
      return item ? item.imei.toLowerCase().includes(searchObject.value.toLowerCase()) : null
    })
  }
  return matchingUnits
})
</script>

<style scoped lang="scss">
.essence {
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  background: #F6F7FA;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      display: flex;
      column-gap: 20px;
      align-items: center;

      .header__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 6px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        color: #292929;

        &-icon {
          background: #EDEFF3;
          border-radius: 100%;
          height: 28px;
          width: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

      }

      .header__info {
        display: flex;
        align-items: center;
        column-gap: 8px;
        justify-content: center;
        border-radius: 12px;
        padding: 10px 12px;
        background: #FFE8DB;
        color: #CA5F32;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }

    &-right {
      width: 32px;
      height: 32px;
      background: #EDEFF3;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__body {
    background: #ffffff;
    border-radius: 12px;

    &-header {
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .user__info {

        &-name {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          color: #292929;
        }

        &-count {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          color: #A6AEB8;
        }
      }

      .search__group {
        position: relative;
        padding: 8px 0 8px 36px;
        width: 339px;
        border-radius: 12px;
        background: #EDEFF3;
        height: 36px;
        display: flex;
        align-items: center;

        &-select {
          ::v-deep(.el-select) {
            width: 77px;
            height: 18px;

            .el-input__inner {
              height: 18px !important;
              background: transparent !important;
              width: 77px;
              padding: 0 0 0 2px !important;
              border:none;
              color:rgba(58, 124, 202, 1);
            }

            .el-input__inner::placeholder {
              color: rgba(58, 124, 202, 1);
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .el-input__suffix {
              right: -4px;
              top: 2px;
            }
          }
        }

        &-input {
          .input-inner{
            width: 100%;
            padding: 8px !important;
            height: 36px !important;
            border:none;
            border-radius: 10px;
            background:  #EDEFF3;
            color: #A6AEB8;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
          .input-inner:focus{
            outline: none;
          }
          //::v-deep(.input-inner.el-input) {
          //  .el-input__inner {
          //    padding-left: 12px !important;
          //    height: 36px !important;
          //    width: 100%;
          //    border: none;
          //
          //  }
          //  .el-input__inner::placeholder {
          //    font-size: 13px;
          //  }
          //}
    }

    &-icon {
      height: 20px;
      width: 20px;
      position: absolute;
      z-index: 111;
      left: 8px;
    }
  }

  .group__settings-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    visibility: hidden;
    .icons {
      width: 28px;
      height: 28px;
      border-radius: 4px;
      background: #F6F7FA;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

&-table {
  .table__content {
    position: relative;
    height: calc(100vh - 190px);
    overflow-y: auto;

    table {
      width: 100%;


      thead {

        th {
          padding: 7px 12px 8px 12px;
          white-space: nowrap;
          font-size: 11px;
          text-align: center;
          font-weight: 400;
          line-height: 120%;
          border: 0.5px solid #cdd4dd;

          &:first-child {
            background: #ffffff;
            border-width: 0.5px;
            border-left: none;
            border-right: none;
            width: 211px;
          }
        }
      }

      tbody {

        td {
          text-align: center;
          background: #DCE1E7;
          border: 0.5px solid #cdd4dd;
          height: 32px;

          &:first-child {
            background: #ffffff;
            border-width: 0.5px;
            border-left: none;
            border-right: none;
            width: 211px;
          }
        }

        .active {
          background: #dbebff;
        }
      }

      .loading {
        width: 100%;
        position: absolute;
        top: 200px;
      }
    }
  }
}
}
}
</style>
