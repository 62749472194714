<template>
  <div class="users" >
    <div class="users__header" @click.stop.prevent="openSearch=false">
      <div class="users__header-title">
        <span>Доступы пользователей по группам</span>
        <UiIcon value="information-v2" height="16px" width="16px"/>
      </div>
      <div class="users__header-right">
        <div class="objects__info" v-if="isSaveButtonActive">
          <UiIcon value="orange-info" width="22px" height="22px"/>
          <span>Осторожно, вы не сохранили изменения!</span>
        </div>
        <el-button
            :class="['users__header-right-add',{'active-btn': isSaveButtonActive }]"
            :disabled="!isSaveButtonActive"
            @click="handleSave"
        >
          Сохранить
        </el-button>
        <div class="close__icon" @click="Store.commit('accessSettings/setIsAccessSettings', false)">
          <UiIcon value="allAccessClose" height="12px" width="12px"/>
        </div>
      </div>
    </div >
    <div class="users__body">
      <div class="users__body-header" @click.stop.prevent="openSearch=false">
        <div class="group__info">
          <div class="group__info-name">{{ userInfo.name }}</div>
          <div class="group__info-count">(ГРУПП: {{ availableGroups.length }})</div>
        </div>
        <div class="group__search">
          <input
              v-model="searchObject"
              class="input-inner"
              placeholder="Поиск"
          />
          <UiIcon
            value="skif_search"
            height="20px"
            width="20px"
            class="group__search-icon"
        />
        </div>
        <div class="group__settings">
          <div class="group__settings-add" @click.stop.prevent="openSearch = !openSearch">
            <UiIcon
                value="add"
                width="16px"
                height="16px"
                color="#3A7CCA"
                class="mt-1"
            />
            <div class="add__name">Добавить в группу</div>
          </div>
          <div class="group__settings-icons" >
            <div class="columns__icon">
              <UiIcon color="#292929" value="columns"  width="20px" height="20px" @click="showModal = !showModal" />
            </div>
            <div class="more__icon">
              <div class="more__icon-radius">
                <UiIcon color="#292929" value="more" width="14px" height="4px" />
              </div>
            </div>
          </div>
          <div v-if="openSearch" class="users__items">
            <AddUsersInGroup
                :available="availableGroups"
                :groups="addUsersToGroup"
                @addGroupToUser="addGroupToUser"
            />
          </div>
        </div>
      </div >
      <div class="users__body-table" :key="tableKey">
        <div class="table__content" >
          <table>
            <thead>
            <tr class="table__row">
              <th class="first__item">-</th>
              <th v-for="column in columns" :key="column.id">
                <div class="table__content_item">{{ column.name }}</div>
              </th>
            </tr>
            </thead>
            <tbody v-if="!loading">
            <tr v-for="row in availableGroups" v-if="row" :key="row.id">
              <td>
                <UserInfoGroups
                    :isPopup="row.popup"
                    :isShowPopup="row.showPopup"
                    :row="row.group"
                    roleName="Пользователи (Группы)"
                    @deleteUser="removeUser"
                />
              </td>
              <td v-for="column in columns" :key="column.id">
                <input
                    type="checkbox"
                    @change="(e) => handleCheckboxChange(row.group.id, column, e.target.checked)"
                    :checked="isActionActive(row, column.id, row.group.id)"
                    :class="{ active: isActionActive(row, column.id, row.group.id) }"
                />
              </td>
            </tr>
            </tbody>
            <div class="loading" v-else>
              <Loader />
            </div>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UiIcon from "./ui/icon.vue"
import AddUsersInGroup from "../AccessSettings/addUserInGroup.vue";
import UserInfoGroups from '../AccessSettings/userInfoGroups.vue'
import Loader from '@/components/unitsNew/components/modalSensors/components/loader.vue'
import { useStore } from '@/store/store.js'
import {computed, defineProps,defineEmits, onMounted, ref, watch} from 'vue'
const emit = defineEmits(['refreshCount','refetchUserGroups'])
const props = defineProps({
  objectInfo: {
    type: Object,
    default: () => {}
  },
  users: {
    type: Array,
    default: () => []
  },
  userInfo: {
    type: Object,
    default: () => {}
  },
  userId: {
    type: String,
    default: ''
  },
  addUsersToGroup: {
    type: Array,
    default: () => []
  }
})

const Store = useStore()
const searchObject = ref('')
const loading = ref(false)
const usersData = ref([])
const openSearch = ref(false)
const showModal = ref(false)
const change = ref(false)
let availableGroups = ref([]);
const isSaveButtonActive = ref(false)

const closePopup = (item) => {
  item.showPopup = false
  item.popup = false
}

const getActionsItems = computed(() => {
  return Store.getters['accessSettings/getAllActions']
})

const columns = ref(
    getActionsItems.value.filter((c) => {
      if (c !== null) {
        return c._unit_action
      }
    })
)

const allGroups = computed(() => {
  return Store.getters['units/unitsGroup']
})

const getUserInfo = computed(() => {
  return Store.getters['users/getUserInfo']
})

const addGroupToUser = async (group) => {
  const index = availableGroups.findIndex(element => element.group.id === group.id);
  if (index !== -1) {
  } else {
    let changeUser = getUserInfo.value[0];
    changeUser.units_groups_access.push({
      units_group: {
        id: group.id
      },
      actions: []
    })
    availableGroups.push({
      group: group,
      user: changeUser
    })
  }
  await handleSave()
  setTimeout(() => {
    emit('refreshCount')
    loading.value = false
  }, 500)
}

const groupsById = computed(() => {
  let arr = []
  allGroups.value.forEach(group => {
    getUserInfo.value.forEach( item => {
      if(item.units_groups_access){
        item.units_groups_access.forEach(unitGroup => {
          if(unitGroup.units_group.id === group.id ) {
            arr.push({
              group: group,
              user: item
            })
          }
        })
      }
    })
  })
  if (searchObject.value) {
    return arr.filter((item) => {
      return item ? item.group.name.toLowerCase().includes(searchObject.value.toLowerCase()) : null
    })
  }
  availableGroups = arr;
  return arr
})


const isActionActive = (actions, columnId, groupId) => {
  const getActions = actions.user.units_groups_access.filter(action => action.units_group.id === groupId);
  if (!getActions[0].actions || getActions[0].actions.length === 0) {
    return false;
  }
  return getActions[0].actions.some(action => action.id === columnId);
}

const removeUser = async (e) => {
  availableGroups.forEach((value, index) => {
    if(value.group.id === e.id) {
      availableGroups[0].user.units_groups_access.forEach((val, key) => {
        if(val.units_group.id === e.id) {
          availableGroups[0].user.units_groups_access.splice(key, 1)
        }
      })
      availableGroups.splice(index, 1)
    }
  })
  loading.value = true
  await handleSave()
  setTimeout(() => {
    emit('refreshCount')
    loading.value = false
  }, 500)
}

const tableKey = computed(() => {
  return `table-${props.groupId}`;
});

const handleCheckboxChange = (groupId, actionId, isChecked) => {
  const groupIndex = groupsById.value.findIndex(u => u.group.id === groupId);
  if (groupIndex !== -1) {
    const userGroupAccessIndex = groupsById.value[groupIndex].user.units_groups_access.findIndex(u => u.units_group.id === groupId)
    if(groupsById.value[groupIndex].user.units_groups_access[userGroupAccessIndex].actions.some(element => element.id === actionId.id)) {
      removeActionById(groupsById.value[groupIndex].user.units_groups_access[userGroupAccessIndex].actions, actionId.id)
    } else {
      groupsById.value[groupIndex].user.units_groups_access[userGroupAccessIndex].actions.push(actionId);
    }
    const actionIndex = groupsById.value[groupIndex].user.units_groups_access[userGroupAccessIndex].actions.findIndex(a => {
      return a.id === actionId.id
    });
    if (actionIndex !== -1) {
      groupsById.value[groupIndex].user.units_groups_access[userGroupAccessIndex].actions[actionIndex].isActive = isChecked;
    }
  }
  isSaveButtonActive.value=true
}

const removeActionById = (array, idToRemove) => {
  const index = array.findIndex(element => element.id === idToRemove);

  if (index !== -1) {
    array.splice(index, 1);
    return true; // Возвращает true, если элемент был найден и удален
  }

  return false; // Возвращает false, если элемент не найден
}

const prepareDataForSaving = () => {
  return availableGroups[0].user;
}
const allActions = computed(() => {
  return Store.getters['accessSettings/getAllActions']
})

const handleSave = () => {
  const dataToSave = prepareDataForSaving();
  Store.dispatch('users/UPDATE', dataToSave);
  isSaveButtonActive.value=false
}

const initializeData = () => {
  let groupsCopy = JSON.parse(JSON.stringify(groupsById.value));
  groupsCopy = groupsCopy.reduce((acc, group) => {
    if (group && group.actions) {
      const userActions = columns.value.map(column => {
        const isActive = group.actions.some(userAction => userAction.id === column.id);
        return {
          ...column,
          isActive
        };
      });
      acc.push({
        user: group,
        actions: userActions
      });
    }
    return acc;
  }, []);
}

onMounted(() => {
  if (groupsById.value.length) {
    initializeData();
  }
});

watch(() => availableGroups.value, (newValue) => {
  availableGroups = groupsById;
  if (newValue.length) {
    initializeData();
  }
}, { immediate: true });

</script>

<style scoped lang="scss">
.users {
  height: 100%;
  background: #F6F7FA;
  padding: 18px 20px;
  width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 6px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      color: #292929;
    }

    &-right {
      display: flex;
      column-gap: 32px;
      align-items: center;
      justify-content: center;
      .objects__info {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 6px;
        border-radius: 12px;
        background: #FFE8DB;
        color: #CA5F32;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding: 10px 12px;
        max-width: 270px;
        height: 36px;
      }
      &-add {
        background: #bec7d3;
        color: #ffffff;
        width: 160px;
        height: 36px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .active-btn {
        background: #3a7cca;
      }
      .close__icon {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: #EDEFF3;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__body {
    background: #FFFFFF;
    border-radius: 12px;

    &-header {
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .group__info {

        &-name {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          color: #292929;
        }

        &-count {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          color: #A6AEB8;
        }
      }

      .group__search {
        position: relative;

        &-icon {
          position: absolute;
          top: 8px;
          left: 8px;
        }
        .input-inner{
          width: 300px;
          padding: 8px 8px 6px 33px !important;
          height: 36px !important;
          border: 1px solid #DCDFE6;
          border-radius: 10px;
          background:  #EDEFF3;
          color: #A6AEB8;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
        .input-inner:focus{
          outline: none;
        }
     }

  .group__settings {
    display: flex;
    column-gap: 32px;

    &-add {
      display: flex;
      align-items: center;
      cursor: pointer;

      .add__name {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        color: #3A7CCA;
      }
    }

    &-icons {
      display: flex;
      align-items: center;
      column-gap: 8px;
      display: none;
      .columns__icon {
        border-radius: 4px;
        width: 28px;
        height: 28px;
        background: #EDEFF3;
        padding: 4px;
      }

      .more__icon {
        border-radius: 4px;
        width: 28px;
        height: 28px;
        background: #EDEFF3;
        padding: 4px;

        &-radius {
          width: 20px;
          height: 20px;
          border: 1.5px solid black;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .users__items {
      position: absolute;
      top: 40px;
      right: 110px;
      z-index: 1000;
    }
  }
}

&-table {
  .table__content {
    padding-bottom: 100px;
    position: relative;
    height: calc(100vh - 190px);
    overflow-y: auto;

    table {
      width: 100%;

      thead {
        th {
          padding: 7px 12px 8px 12px;
          white-space: normal;
          font-size: 11px;
          text-align: center;
          font-weight: 400;
          line-height: 120%;
          border: 0.5px solid #cdd4dd;

          &:first-child {
            background: #ffffff;
            border-width: 0.5px;
            border-left: none;
            border-right: none;
            width: 211px;
          }
        }
      }

      tbody {
        td {
          text-align: center;
          background: #dbebff;
          border: 0.5px solid #cdd4dd;
          height: 32px;

          &:first-child {
            background: #ffffff;
            border-width: 0.5px;
            border-left: none;
            border-right: none;
          }
        }

        .active {
          background: #dbebff;
        }
      }

      .loading {
        width: 100%;
        position: absolute;
        top: 200px;
      }
    }
  }
}
}
}

::v-deep(.el-input__inner) {
width: 300px;
height: 36px;
}
::v-deep(.input-inner.el-input) {
.el-input__inner {
padding-left: 33px !important;
}
}
</style>
